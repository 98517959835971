import React from 'react';
import HeroBanner from '../HeroBanner/HeroBanner'
import Frontpage from '../../pages/forside/Frontpage';
import Coronavirus from '../../pages/coronavirus/Coronavirus';
import Statistik from '../../pages/statistik/Statistik';
import News from '../../pages/nyheder/News';
import Vaccine from '../../pages/vaccine/Vaccine';
import FooterComponent from '../FooterComponent/FooterComponent';
import NewPage from '../../pages/nyheder/newpage';
import NotFoundPage from '../../pages/notfound/NotFoundPage';
import Precautions from '../../components/Precautions/Precautions';
import VirusAbout from '../../components/VirusAbout/VirusAbout';
import PointerLateEffects from '../../components/PointerLateEffects/PointerLateEffects';
import './Burgermenu.css';
import { slide as Menu } from "react-burger-menu";
import { ReactComponent as IconHome } from './../../assets//home-solid.svg';
import { ReactComponent as IconSyringe } from './../../assets//syringe-solid.svg';
import { ReactComponent as IconVirus } from './../../assets/virus-solid.svg';
import { ReactComponent as IconNewspaper } from './../../assets//newspaper-regular.svg';
import { ReactComponent as IconChart } from './../../assets//chart-bar-solid.svg';
// FontAwesome ikoner brugt med gratis licens - https://creativecommons.org/licenses/by/4.0/legalcode
import { BrowserRouter as Router, Redirect, Switch, Route, NavLink } from 'react-router-dom';

function Navbar() {
    return (
        <div>
    <Router>
      <div>
        <div className="container">
          <ul>

            <NavLink to="/" exact activeClassName="activeMainLink">
              <li><p className="navbar-text"><IconHome className='icons' />Forside</p></li>
            </NavLink>
            <NavLink to="/coronavirus" exact activeClassName="activeMainLink">
              <li><p className="navbar-text"><IconVirus className='icons' />Coronavirus</p></li>
            </NavLink>
            <NavLink to="/vaccine" exact activeClassName="activeMainLink">
            <li><p className="navbar-text"><IconSyringe className='icons' />Vaccinen</p></li>
            </NavLink>
            <NavLink to="/statistik" exact activeClassName="activeMainLink">
            <li><p className="navbar-text"><IconChart className='icons' />Statistik</p></li>
            </NavLink>
            <NavLink to="/news" exact activeClassName="activeMainLink">
            <li><p className="navbar-text"><IconNewspaper className='icons' />Nyheder</p></li>
            </NavLink>
          </ul>
        </div>
        <HeroBanner /><br />
        {/* Billede på banner taget af Kuma Kum - https://unsplash.com/photos/SRMb8-JUCHg?utm_source=unsplash&utm_medium=referral&utm_content=creditShareLink */}
        <Switch>
          <Route path="/" exact component={Frontpage} />
          <Route path="/coronavirus" component={Coronavirus} />
          <Route path="/news" component={News} />
          <Route path="/statistik" component={Statistik} />
          <Route path="/vaccine" component={Vaccine} />
          <Route path="/newpage" exact component={NewPage} />
          <Route path="/forebyggelse" exact component={Precautions} />
          <Route path="/omvirussen" exact component={VirusAbout} />
          <Route path="/senfolger" exact component={PointerLateEffects} />
          <Route path="/404" component={NotFoundPage} />
                    <Redirect to="/404" />
        </Switch>
      </div>
      <FooterComponent />

{/* Mobil Navbar / react-burger-menu */}

{/* Make menu into a State, and when a link is clicked it should "turn off" */}

<div className="burger-nav">
        <Menu right>
            <div>
          <ul className="burgermenus">

            <NavLink to="/">
              <li><p>Forside</p></li>
            </NavLink>
            <NavLink to="/coronavirus">
              <li><p>Symptomer</p></li>
            </NavLink>
            <NavLink to="/senfolger">
              <li><p>Senfølger</p></li>
            </NavLink>
            <NavLink to="/omvirussen">
              <li><p>Om Virussen</p></li>
            </NavLink>
            <NavLink to="/forebyggelse">
              <li><p>Forebyggelse</p></li>
            </NavLink>
            <NavLink to="/vaccine">
            <li><p>Vaccinen</p></li>
            </NavLink>
            <NavLink to="/statistik">
            <li><p>Statistik</p></li>
            </NavLink>
            <NavLink to="/news">
            <li><p>Nyheder</p></li>
            </NavLink>
          </ul>
        </div>

        </Menu>
            </div>
            </Router>
        </div>
    );
}

  export default Navbar;