import React from 'react'
import MiniNavbar from '../../components/Navbar/MiniNavbar';
import { ReactComponent as CovidVirus } from '../../assets/covid-virus.svg';
import { ReactComponent as CBats } from '../../assets/circle-bats.svg';
import { ReactComponent as CBedridden } from '../../assets/circle-bedridden.svg';
import { ReactComponent as CChina } from '../../assets/circle-chinaflag.svg';
import { ReactComponent as CCrown } from '../../assets/circle-covidcrown.svg';
import { ReactComponent as CViruses } from '../../assets/circle-viruses.svg';
import { ReactComponent as CLungs } from '../../assets/circle-lungs.svg';
import { ReactComponent as CPetri } from '../../assets/circle-petri.svg';
import { ReactComponent as CVariant } from '../../assets/circle-variants.svg';
import './Virusabout.css';

export default function VirusAbout() {
    return (
        <div>
            <MiniNavbar />

            <div className="aboutwrap">

            <h2 className="aboutheader">Information om <span className="orange">Coronavirus</span></h2>
            <hr className="abouthr" />
            <p className="about-p">Bevæg musen over ikonerne for at læse mere om Coronavirus!</p>

            <div className="abso-box">
            <CovidVirus className="covidball"/>

            <div className="mainhover batplacement">
            <CBats className="icon-style"/>
            <div className="txt-box txtboxleft leftbox">
                <p><span className="orange">Coronavirus</span> er almindelig fundet i flere forskellige dyr. Den kan i sjældne tilfælde smitte fra dyr til mennesker. Man tror stadig, at <span className="orange">COVID-19</span> stammer fra flagermus.</p>
            </div>
            </div>


            <div className="mainhover bedplacement">
            <CBedridden className="icon-style"/>
            <div className="txt-box txtboxright rightbox">
                <p>Behandling for alvorlige tilfælde af <span className="orange">COVID-19</span> sker ved at behandle symptomerne samt behandling med specifikt antiviralt lægemiddel.</p>
            </div>
            </div>

            <div className="mainhover chinaplacement">
            <CChina className="icon-style"/>
            <div className="txt-box txtboxright rightbox">
                <p><span className="orange">COVID-19</span> dukkede op første gang i Wuhan, Kina i december 2019. Man er ikke sikker på hvor det startede, men man regner med det var fra et fiskemarked. </p>
            </div>
            </div>

            <div className="mainhover crownplacement">
            <CCrown className="icon-style"/>
            <div className="txt-box txtboxright rightbox">
                <p><span className="orange">Coronavirus</span> har sit navn efter dens udseende. Når man ser den under et mikroskop, har virussen spidse ender, ligesom en krone. Ordet <span className="orange">Corona</span> er latinsk for <span className="orange">Krone</span>.</p>
            </div>
            </div>

            <div className="mainhover virusesplacement">
            <CViruses className="icon-style"/>
            <div className="txt-box txtboxright rightbox">
                <p>Der er i Danmark fire <span className="orange">vacciner</span>, der har fået betinget godkendelse. Pfizer/BioNTech, Moderna, AstraZeneca og Johnson & Johnson.</p>
            </div>
            </div>

            <div className="mainhover lungsplacement">
            <CLungs className="icon-style"/>
            <div className="txt-box txtboxleft leftbox">
                <p><span className="orange">COVID-19</span> er en sygdom forsaget af <span className="orange">SARS-CoV-2</span> der kan give luftvejsinfektioner. Dette kan være i den øvre del (næse, bihule, hals) eller nedre del (lunger og luftrør).</p>
            </div>
            </div>

            <div className="mainhover petriplacement">
            <CPetri className="icon-style"/>
            <div className="txt-box txtboxleft leftbox">
                <p><span className="orange">SARS-CoV-2</span> er en del af Coronavirus-familien. Forkortelsen står for <span className="orange">S</span>ervere <span className="orange">A</span>cute <span className="orange">R</span>espiratory <span className="orange">S</span>yndrome <span className="orange">Co</span>rona<span className="orange">V</span>irus <span className="orange">2</span>.</p>
            </div>
            </div>

            <div className="mainhover variantplacement">
            <CVariant className="icon-style"/>
            <div className="txt-box txtboxleft leftbox">
                <p>Der er på nuværende tidspunkt tre forskellige varianter af <span className="orange">COVID-19</span>. Engelsk, Sydafrikansk og Brasiliensk/Japansk. Disse har formentligt større smitsomhed.</p>
            </div>
            </div>

            </div>
            <div className="vac-linkbox">
    <p>Referencer: </p>
    <a className="vac-link" href="https://covid19.ssi.dk/om-covid19">SSI - Om covid-19</a><br />
    <a className="vac-link" href="https://www.cdc.gov/coronavirus/2019-ncov/faq.html#Basics">CDC - Frequently Asked Questions</a><br />
    <a className="vac-link" href="https://www.webmd.com/lung/coronavirus">Coronavirus and COVID-19: What You Should Know</a><br />
    </div>
        </div>
        </div>
    )
}
