import React from 'react';
import './FooterComponent.css';

function FooterComponent() {

    return (
        <>
            <div className="footer-main">
                <section className="boxes">
                    <div>
                        <h3 className="orange">Kontaktoplysninger:</h3>
                        <br />
                        <p>Kontakt på:</p>
                        <p>Mail@pestdoktor.dk</p>
                    </div>

                    <div>
                        <h3>Om Os:</h3>
                        <br />
                        <p>Pestdoktor.dk er produceret til at skitsere de aktuelle virusudbrud i verden, samt informere om disse</p>
                    </div>

                    <div>
                        <h3 className="orange">Copyright:</h3>
                        <br />
                        <p>2021 By Anderson</p>
                    </div>

                </section>
            </div>
        </>
    )
}

export default FooterComponent;