import React from 'react';
import './Burgermenu.css';

import { BrowserRouter as Router, Redirect, Switch, Route, NavLink } from 'react-router-dom';

function MiniNavbar() {
    return (
        <div>

<div className="mini-menu">
        <NavLink to="/coronavirus" exact activeClassName="activeLink">
            <div className="testmenu"><p className="menu-p">Symptomer</p></div>
        </NavLink>
        <NavLink to="/senfolger" exact activeClassName="activeLink">
            <div className="testmenu"><p className="menu-p">Senfølger</p></div>
        </NavLink>
        <NavLink to="/omvirussen" exact activeClassName="activeLink">
            <div className="testmenu"><p className="menu-p">Om Virussen</p></div>
        </NavLink>
        <NavLink to="/forebyggelse" exact activeClassName="activeLink">
            <div className="testmenu"><p className="menu-p">Forebyggelse</p></div>
        </NavLink>
</div>
        </div>
    
    );
}

  export default MiniNavbar;