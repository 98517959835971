import React, { useState } from 'react';
import './PointerLateEffects.css';
import MiniNavbar from '../../components/Navbar/MiniNavbar';
import { ReactComponent as PdHoldup } from './pestdoktor_anatomi_new.svg';
import { ReactComponent as PdBrainOrange } from './pd_laterheadone.svg';
import { ReactComponent as PdBrainRed } from './pd_laterheadtwo.svg';
import { ReactComponent as PdNose } from './pd_nose.svg';
import { ReactComponent as PdMouth } from './pd_mouth.svg';
import { ReactComponent as PdHeart } from './pd_heart.svg';
import { ReactComponent as PdOrangeLung } from './pd_lungone.svg';
import { ReactComponent as PdRedLung } from './pd_lungtwo.svg';
import { ReactComponent as PdBloodClot } from './pd_bloodclot.svg';
import { ReactComponent as PdMuscleAche } from './pd_muscle.svg';
import { ReactComponent as PdJointPain } from './pd_joint.svg';



function PointerLateEffect() {
  
    const [nonChosen, setNonChosen] = useState(true);
    const [brainOrange, setBrainOrange] = useState(false);
    const [brainRed, setBrainRed] = useState(false);
    const [noseOrange, setNoseOrange] = useState(false);
    const [mouthOrange, setMouthOrange] = useState(false);
    const [heartCircle, setHeartCircle] = useState(false);
    const [orangeLung, setOrangeLung] = useState(false);
    const [redLung, setRedLung] = useState(false);
    const [bloodClot, setBloodClot] = useState(false);
    const [muscleAche, setMuscleAche] = useState(false);
    const [jointPain, setJointPain] = useState(false);
    
    return (
      <div>
        <MiniNavbar />
        <div className="pd-wrap">
        <h1 className="center-header">Senfølger af <span className="orange">Coronavirus</span></h1>
        <hr className="h1-hr" />
        <div className="pd-main">
  
  {/* Mouse events for hoverable cirkler */}

          <span className="dot dot-mildbrain" onMouseEnter={() => {setBrainOrange(true); setNonChosen(false);}}
            onMouseLeave={() => {setBrainOrange(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-severebrain" onMouseEnter={() => {setBrainRed(true); setNonChosen(false);}}
            onMouseLeave={() => {setBrainRed(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-nose" onMouseEnter={() => {setNoseOrange(true); setNonChosen(false);}}
            onMouseLeave={() => {setNoseOrange(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-coughing" onMouseEnter={() => {setMouthOrange(true); setNonChosen(false);}}
            onMouseLeave={() => {setMouthOrange(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-chest-orange" onMouseEnter={() => {setRedLung(true); setNonChosen(false);}}
            onMouseLeave={() => {setRedLung(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-heart-red" onMouseEnter={() => {setHeartCircle(true); setNonChosen(false);}}
            onMouseLeave={() => {setHeartCircle(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-chest-red" onMouseEnter={() => {setOrangeLung(true); setNonChosen(false);}}
            onMouseLeave={() => {setOrangeLung(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-bloodclot" onMouseEnter={() => {setBloodClot(true); setNonChosen(false);}}
            onMouseLeave={() => {setBloodClot(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-muscles" onMouseEnter={() => {setMuscleAche(true); setNonChosen(false);}}
            onMouseLeave={() => {setMuscleAche(false); setNonChosen(true);}}>
          </span>

          <span className="dot dot-joint" onMouseEnter={() => {setJointPain(true); setNonChosen(false);}}
            onMouseLeave={() => {setJointPain(false); setNonChosen(true);}}>
          </span>
  
  
  {/* Actions, når hover executer event vis image */}
  
          {nonChosen && (
          <PdHoldup className="pd-later-first" />
          )}
  
          {brainOrange && (
            <PdBrainOrange className="pd-later-style" />
          )}
  
          {brainRed && (
            <PdBrainRed className="pd-later-style" />
          )}

          {noseOrange && (
            <PdNose className="pd-later-style" />
          )}

          {mouthOrange && (
            <PdMouth className="pd-later-style" />
          )}
  
          {heartCircle && (
            <PdHeart className="pd-later-style" />
          )}
  
          {orangeLung && (
            <PdOrangeLung className="pd-later-style" />
          )}
  
          {redLung && (
            <PdRedLung className="pd-later-style" />
          )}

          {bloodClot && (
            <PdBloodClot className="pd-later-style" />
          )}

          {muscleAche && (
            <PdMuscleAche className="pd-later-style" />
          )}

          {jointPain && (
            <PdJointPain className="pd-later-style" />
          )}
          
        </div>
  
  {/* Actions, når hover executer event tekst */}
  
  {nonChosen && (
      <div className="textwrap">
      <h3 className="pointer-header">Information om senfølger</h3>
      <p className="centertxt">Før musen over et punkt på kroppen for at få information om de tilhørende senfølger.</p><br />
      <p className="le-link">Referencer: </p>
    <a className="le-link" href="https://www.mayoclinic.org/diseases-conditions/coronavirus/in-depth/coronavirus-long-term-effects/art-20490351">Mayo Clinic COVID-19 Long-term effects</a><br />
    <a className="le-link" href="https://www.cdc.gov/coronavirus/2019-ncov/long-term-effects.html">CDC - Long-Term Effects of Covid-19</a><br />
      </div>
  )}
  
  {brainOrange && (
      <div className="textwrap">
      <h3 className="pointer-header">Hjerne og sind</h3>
      <p className="centertxt">Hovedpine og træthed, som også er et symptom på Coronavirus, kan være vedvarende som følge af sygdommen.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}
  
  {brainRed && (
      <div className="textwrap">
      <h3 className="pointer-header">Hjerne og sind</h3>
      <p className="centertxt">Alvorlige senfølger af Coronavirus er heldigvis sjældne. Hjerne og sind kan påvirkes på følgende måde:</p><br />
      <p className="centertxt">- Hukommelsesproblemer.</p>
      <p className="centertxt">- Koncentrationsbesvær.</p>
      <p className="centertxt">- Søvnproblemer.</p>
      <p className="centertxt">- Depression og angst.</p>
      <p className="centertxt">- Slagtilfælde.</p>
      <p className="centertxt">- Krampeanfald.</p>
      <br />
      <p className="p-center redtxt">Dette er alvorlige senfølger af Coronavirus.</p>
      </div>
  )}

  {noseOrange && (
      <div className="textwrap">
      <h3 className="pointer-header">Næse og Mund</h3>
      <p className="centertxt">Smags- og lugtesansen kan forsvinde efter at have været smittet med Coronavirus.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}

  {mouthOrange && (
      <div className="textwrap">
      <h3 className="pointer-header">Hoste</h3>
      <p className="centertxt">Det er muligt at have vedvarende hoste efter at have været smittet med Coronavirus.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}
  
  {heartCircle && (
      <div className="textwrap">
      <h3 className="pointer-header">Hjertet</h3>
      <p className="centertxt">Der kan være flere af hjertets funktioner, som kan blive ramt af senfølger. Disse er som følgende:</p><br />
      <p className="centertxt">- Hurtig hjerterytme.</p>
      <p className="centertxt">- Inflammation af hjertemusklen.</p>
      <p className="centertxt">- Mulig forhøjet chance for hjertesvigt.</p><br />
      <p className="p-center redtxt">Dette er alvorlige senfølger af Coronavirus.</p>
      </div>
  )}
  
  {orangeLung && (
      <div className="textwrap">
      <h3 className="pointer-header">Lunger og bryst</h3>
      <p className="centertxt">Abnormiteter i luftfunktionen, der kan skabe langsigtede problemer med vejrtrækning.</p><br />
      <p className="p-center redtxt">Dette er alvorlige senfølger af Coronavirus.</p>
      </div>
  )}
  
  {redLung && (
      <div className="textwrap">
      <h3 className="pointer-header">Lunger og bryst</h3>
      <p className="centertxt">Mulige senfølger kan være smerter i brystet samt stakåndethed.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}
  
  {bloodClot && (
      <div className="textwrap">
      <h3 className="pointer-header">Blodprop</h3>
      <p className="centertxt">En af følgerne af Coronavirus er, at blodets celler har større chance for at klumpe sammen og give blodpropper. Forskning peger dog på, at blodpropperne fra at have haft Coronavirus forsager mikroskopiske blodpropper.</p><br />
      <p className="p-center redtxt">Dette er alvorlige senfølger af Coronavirus.</p>
      </div>
  )}

    {muscleAche && (
      <div className="textwrap">
      <h3 className="pointer-header">Muskler</h3>
      <p className="centertxt">Det er muligt at få muskelsmerte på længere sigt efter at have været smittet med Coronavirus.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}
  
  {jointPain && (
      <div className="textwrap">
      <h3 className="pointer-header">Led</h3>
      <p className="centertxt">Det er muligt at få ledsmerte på længere sigt efter at have været smittet med Coronavirus.</p><br />
      <p className="p-center">Dette er et almindeligt senfølge af Coronavirus.</p>
      </div>
  )}


  
  </div >
      </div >
    );
  }
  
  export default PointerLateEffect;