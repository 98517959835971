import React, { useEffect, useState } from 'react';
import '../../App.css';
import CovidSummary from './CovidSummary';
import axiostwo from './axiostwo';
import './Graph.css';

function SummaryBox() {

  const [totalConfirmed, setTotalConfirmed] = useState(0);
  const [totalRecovered, setTotalRecovered] = useState(0);
  const [totalDeaths, setTotalDeaths] = useState(0);
  const [loading, setLoading] = useState(true);
  const [confirmedToday, setConfirmedToday] = useState(0);
  const [deathsToday, setDeathsToday] = useState(0);
  const [recoveredToday, setRecoveredToday] = useState(0);

  useEffect(() => {
    axiostwo.get(`/v2/countries/denmark`)
    .then(res => {
      setLoading(false)

      if(res.status === 200){
        setConfirmedToday(res.data.todayCases);
        setRecoveredToday(res.data.todayRecovered);
        setDeathsToday(res.data.todayDeaths);
        setTotalConfirmed(res.data.cases);
        setTotalRecovered(res.data.recovered);
        setTotalDeaths(res.data.deaths);
      }
    })
    .catch(error => {
      console.log(error)
    })

  }, []);



  if(loading){
    return <p className="graph-p">Data ikke loadet, vær venlig at genindlæse siden!</p>
  }

  return (
    <div className="App infobox">
      <CovidSummary
        totalConfirmed={totalConfirmed}
        totalRecovered={totalRecovered}
        totalDeaths={totalDeaths}
        confirmedToday={confirmedToday}
        deathsToday={deathsToday}
        recoveredToday={recoveredToday}
      />
    </div>
  );
}

export default SummaryBox;
