import React, { useState, useEffect } from 'react';
import { ReactComponent as AboutVirus } from '../../assets/fp-omcovid.svg';
import { ReactComponent as AboutBiohazard } from '../../assets/fp-biohazard.svg';
import { ReactComponent as AboutHandsanitize } from '../../assets/fp-handsanitize.svg';
import { ReactComponent as Vaccine } from '../../assets/fp-vaccine.svg';
import { ReactComponent as ScytheFlag } from '../../assets/scytheflag.svg';
import SummaryBox from '../../components/Graph/SummaryBox';
import LatestPost from '../../components/LatestPost/LatestPost';
import { BrowserRouter as Redirect, NavLink } from 'react-router-dom';
import './Frontpage.css';
import '../../index.css';

function Frontpage() {

const [apiData, setApiData] = useState(null);

useEffect(() => {
    async function getCategory() {
    const fetchHeaders = new Headers();
    fetchHeaders.append('Accept', 'application/json');
    try {
        const request = await fetch(`https://api.covid19api.com/summary`);
        const response = await request.json();
        setApiData(response.Countries[46]);
    } catch (error) {
    }
    }
    
    getCategory()
    }, [])


    return (
        <>
            <div>
                <h1 className="iconHeader">Nyeste <span className="orange">viden</span> om <span className="orange">virusudbruddet</span>,<br /> samlet på ét sted.</h1>

                <div className="gridflex">
                <div className="gridtester">

                <div className="first">
                    <AboutVirus className="hexagrid" />
                    <h2>Om virussen</h2>
                    <p className="text">Generel information om den nuværende virussmitte.</p><br />
                </div>

                <div className="second">
                <AboutBiohazard className="hexagrid" />
                <h2>Antal Smittede</h2>
                    <p className="text">Data om antal af smittede personer i Danmark og verden.</p>
                </div>

                <div className="second">
                <AboutHandsanitize className="hexagrid" />
                <h2>Hygiejneråd</h2>
                    <p className="text">Gode råd, der kan mindske smitten og chancen for denne.</p>
                </div>

                <div className="second">
                <Vaccine className="hexagrid" />
                <h2>Vaccinen</h2>
                    <p className="text">Information om de gængse vacciner.</p><br />
                </div>

                </div>
                </div>
                <div>
                    <div className="gridflex">
                    <div className="gridtester">
                        <div>
                    <NavLink to="/coronavirus">
                    <p className="orange tb-1-p">Læs Mere</p>
                        </NavLink>
                        </div>

                        <div>
                        <NavLink to="/statistik">
                    <p className="orange tb-1-p txt-margin">Læs Mere</p>
                        </NavLink>
                        </div>

                        <div>
                        <NavLink to="/forebyggelse">
                    <p className="orange tb-1-p txt-margin">Læs Mere</p>
                        </NavLink>
                        </div>

                        <div>
                        <NavLink to="/vaccine">
                    <p className="orange tb-1-p">Læs Mere</p>
                        </NavLink>
                        </div>

                    </div>
                    </div>

                    <hr className="rule-split" /></div>

                <div className="header-container">
                </div>

                    <div className="lol">
                <div className="img-container">
                    <section>
                    <h2 className="h2-header"><span className="orange">Corona</span> i Danmark:</h2>
                    <ScytheFlag className="dkflag" />
                <SummaryBox />

                </section>
                <section>
                <h2 className="h2-header2">Seneste nyheder om <span className="orange">Coronavirus:</span></h2>
                    <LatestPost />
                    </section>

                    <div className="fetch-div">

                </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default Frontpage;