import React, { useState } from 'react';
import '../../App.css';
import './Coronavirus.css';

import PestdoctorPointer from '../../components/PestdoctorPointer/PestdoctorPointer';
import Precautions from '../../components/Precautions/Precautions';
import VirusAbout from '../../components/VirusAbout/VirusAbout';
import PointerLateEffect from '../../components/PointerLateEffects/PointerLateEffects';
import MiniNavbar from '../../components/Navbar/MiniNavbar';


function Coronavirus() {

const [covidPrecautions] = useState(false);
const [doctorFigure] = useState(true);
const [aboutVirus] = useState(false);
const [covidLateEffect] = useState(false);

return (
    <div className="center-div">

      <MiniNavbar />

{doctorFigure && (
    <div>
    <PestdoctorPointer />
    </div>
)}


{covidPrecautions && (
    <div>
    <Precautions />
    </div>
)}

{aboutVirus && (
    <div>
    <VirusAbout />
    </div>
)}

{covidLateEffect && (
    <div>
    <PointerLateEffect />
    </div>
)}

    </div >
  );
}



export default Coronavirus;
