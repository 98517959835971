import React from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route, NavLink } from 'react-router-dom';
import './Notfound.css';

function NotFoundPage() {

    return(
        <div>
            <div className="notfound">
                <p>Denne side eksisterer ikke!</p>
            <NavLink to="/">
                <button className="btnreturn">Vend tilbage til forsiden</button>
            </NavLink>
            </div>
        </div>
    );
}

export default NotFoundPage;