import React from 'react'
import './Precautions.css';
import MiniNavbar from '../../components/Navbar/MiniNavbar';
import { ReactComponent as WashHands } from '../../assets/handwash.svg';
import { ReactComponent as HandSanitizer } from '../../assets/hygene-sanitizer.svg';
import { ReactComponent as SelfIsolation } from '../../assets/selfisolation-new.svg';
import { ReactComponent as CoughGuy } from '../../assets/cough-guy-new.svg';
import { ReactComponent as NoHandshake } from '../../assets/handshake-new.svg';
import { ReactComponent as KeepDistance } from '../../assets/distancing-new.svg';
import { ReactComponent as CleanUp } from '../../assets/cleaning-new.svg';
import { ReactComponent as MaskOn } from '../../assets/mask-new.svg';

export default function Precautions() {


    return (
        <div>
        <MiniNavbar />
        <div className="article-wrap">
            <h2 className="pre-headtxt">Tips til forebyggelse mod smitte af <span className="orange">Coronavirus</span></h2>
            <hr className="h1-hr" />
            <div className="pre-wrapper wrapper-one">
                <article className="pre-article">
                <h3>Håndvask</h3>
                    <p>Har du, eller er du væk hjemmefra i blandt andre mennesker, er et af de bedste råd mod smitte at vaske dine hænder tit og ofte. Det samme gælder, når du vender retur efter at have være ude, for eksempel at handle. Husk at brug sæbe!</p>
                    </article>
                <div className="alignicon">
                <WashHands className="hexIconspre washicon" />
                </div>
                </div>


                <div className="pre-wrapperleft">
            <div className="handsanitizeicon">
                <HandSanitizer className="hexIconsleft washiconleft" />
            </div>
                <article className="pre-article">
                <h3>Afspritten</h3>
                    <p>Håndsprit dræber virus og bakterier. Derfor er det en god idé at afspritte sine hænder når man har muligheden.</p>
                    </article>
                </div>

                <div className="pre-wrapper">
                <article className="pre-article">
                <h3>Selvisolering</h3>
                    <p>Er du smittet, eller har du mistanke om dette, er selvisolering vigtigt. Du stopper derved smittekæden, og udsætter ikke andre for fare.</p>
                    </article>
                <div className="alignicon">
                <SelfIsolation className="hexIconspre washiconright" />
                </div>
                </div>

                <div className="pre-wrapperleft">
            <div className="handsanitizeicon">
                <CoughGuy className="hexIconsleft washiconleft" />
            </div>
                <article className="pre-article">
                <h3>Host i albuen</h3>
                    <p>Skal du hoste eller nyse skal det foregå ned i albuen. Derved spredes bakterierne og virus ikke direkte ud i rummet.</p>
                    </article>
                </div>

                <div className="pre-wrapper">
                <article className="pre-article" className="pre-article">
                <h3>Hold igen med håndgiven</h3>
                    <p>Da smitten kan ske igennem berøring frabedes det at give hinanden hånd.</p>
                    </article>
                <div className="alignicon">
                <NoHandshake className="hexIconspre washiconright" />
                </div>
                </div>

                <div className="pre-wrapperleft">
            <div className="handsanitizeicon">
                <KeepDistance className="hexIconsleft washiconleft" />
            </div>
                <article className="pre-article">
                <h3>Hold afstand</h3>
                    <p>Social distancering er vigtig for at stoppe smitten. Ses derfor kun med et få antal mennesker, check eventuelt regeringens seneste udmelding.</p>
                    </article>
                </div>

                <div className="pre-wrapper">
                <article className="pre-article">
                <h3>Rengøring</h3>
                    <p>Rengør tit og ofte. Dette er med til at dræbe virussen på overflader. Er du smittet er rengøring også vigtigt!</p>
                    </article>
                <div className="alignicon">
                <CleanUp className="hexIconspre washiconright" />
                </div>
                </div>

                <div className="pre-wrapperleft  wrapper-last">
            <div className="handsanitizeicon">
                <MaskOn className="hexIconsleft washiconleft" />
            </div>
                <article className="pre-article">
                <h3>Brug maske</h3>
                    <p>Er du ude fra hjemmet skal der bruges maske. Dette foregår blandt andet i offentlig transport og når der handles.</p>
                    </article>
                </div>

    <div className="vac-linkbox">
    <p>Referencer: </p>
    <a className="vac-link" href="https://www.sst.dk/da/udgivelser/2020/covid-19-forebyggelse-af-smittespredning">SSI - COVID-19: Forebyggelse af smittespredning</a><br />
    <a className="vac-link" href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html">CDC - How to Protect Yourself & Others</a><br />
    </div>

        </div>
        </div>
    )
}
