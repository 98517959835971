import React from 'react';
import '../../App.css';
import './Vaccine.css';
import { ReactComponent as SnakeRight } from '../../assets/vaccine/rodscythe-right.svg';
import { ReactComponent as SnakeLeft } from '../../assets/vaccine/rodscythe-left.svg';
import { ReactComponent as LogoAstra } from '../../assets/vaccine/astrazeneca.svg';
import { ReactComponent as LogoModerna } from '../../assets/vaccine/modernalogo.svg';
import { ReactComponent as LogoPfizer } from '../../assets/vaccine/pfizerbiontechlogo.svg';
import { ReactComponent as LogoJJ } from '../../assets/vaccine/jandjlogo.svg';
import { ReactComponent as Vaccine } from '../../assets/vaccine/vaccine.svg';
import { ReactComponent as VcFace } from '../../assets/vaccine/vc-face.svg';
import { ReactComponent as VcFever } from '../../assets/vaccine/vc-fever.svg';
import { ReactComponent as VcHeadache } from '../../assets/vaccine/vc-headache.svg';
import { ReactComponent as VcInsomnia } from '../../assets/vaccine/vc-insomnia.svg';
import { ReactComponent as VcLymphs } from '../../assets/vaccine/vc-lymphs.svg';
import { ReactComponent as VcNausea } from '../../assets/vaccine/vc-nausea.svg';
import { ReactComponent as VcRash } from '../../assets/vaccine/vc-rash.svg';
import { ReactComponent as VcPain } from '../../assets/vaccine/vc-vaccinepain.svg';

function Coronavirus() {

return (
<div>
  <div className="vaccinewrap">

    <h2 className="vaccineheader">Information om vacciner mod <span className="orange">Coronavirus</span></h2>
    <hr className="abouthr" />

    <div className="vac-pad">
    <div className="vactextwrap">
    <SnakeLeft className="snakes"/>
    <p>På nuværende tidspunkt er der fire vacciner, der har fået betinget godkendelse. En betinget godkendelse vil sige, at eksperter har vurderet, at der er solid dokumentation for, at COVID-19 vaccinerne både virker og er sikre. </p>
    <SnakeRight className="snakes"/>
    </div>


{/* Pfizer column start */}

<hr className="vac-hr" />
    <h3 className="miniheader"><span className="orange">Pfizer BioNTech</span> Vaccinen</h3>
      <div className="az-v">
      <LogoPfizer className="astraz"/>
      <div className="vaccinegrid">
      <div className="vacbox-one">
      <p className="vac-p">Pfizer og BioNTech er to medicinalvirksomheder, der har slået sig sammen og lavet Covid-19 vaccinen med navnet <span className="orange">Comirnaty</span>.</p>
      <br />
      <p className="vac-p">BioNTech er en tysk bioteknologisk virksomhed, som er kendt for at fremstille lægemidler til kræftbehandling.</p>
      <br />
      <p className="vac-p">Pfizer er en amerikansk medicinalproducent med hovedsæde i New York. Virksomheden blev grundlagt af tyskeren Charles Pfizer.</p>
      <br />
      <p className="vac-p">Comirnaty var den første vaccine, som blev godkendt til brug mod COVID-19 den 21. december 2020.</p>
      <br />
      <p className="vac-p">Den estimerede effektivitet af Comirnaty ligger på 95%.</p>
      <br />
      <p className="vac-p">Bliver du vaccineret med Comirnaty vaccinen skal du have to stik. Andet stik modtages 21 dage efter du har modtaget det første stik.</p>
      </div>

      <div className="vacbox-two">
      <div className="h3-wrap">
        <h3 className="vac-h3">Eventuelle Bivirkninger</h3>
        </div>
        <div className="vacgrid-small">

        <div>
          <VcHeadache />
          <p>Hovedpine og træthed.</p>
          </div>
          <div>
          <VcNausea />
          <p>Kvalme og/eller opkast.</p>
          </div>
          <div>
          <VcLymphs />
          <p>Hævede lymfeknuder under den arm, hvori man er vaccineret.</p>
          </div>
          <div>
          <VcPain />
          <p>Ømhed på indstiksstedet. Smerter på injektionsstedet. Muskelsmerter og utilpashed. Ledsmerter.</p>
          </div>
          <div>
          <VcFever />
          <p>Feber og kulderystelser.</p>
          </div>
          <div>
          <VcInsomnia />
          <p>Søvnløshed.</p>
          </div>
          <div>
          <VcRash />
          <p>Udslæt, hudkløe eller rødme ved injektionsstedet.</p>
          </div>
          <div>
          <VcFace />
          <p>Akut ansigtslammelse og hævelse i ansigtet.</p>
          <p className="red">(Sjældent)</p>
          </div>

        </div>
      </div>
      </div>
    </div>
    
      {/* Pfizer column end */}


{/* Moderna column start */}
<hr className="vac-hr" />
<h3 className="miniheader"><span className="orange">Moderna</span> Vaccinen</h3>
      <div className="az-v">
      <LogoModerna className="moderna"/>
      <div className="vaccinegrid">
      <div className="vacbox-one">
      <p className="vac-p">Moderna er et amerikansk biotekselskab med hovedsæde i Cambrige, Massachusetts, som er kendt for at udvikle vacciner.</p>
      <br />
      <p className="vac-p">Modernas vaccine kom ud som nummer to i kampen mod COVID-19. Den blev godkendt den 6. januar 2021.</p>
      <br />
      <p className="vac-p">Effektiviteten på Modernas vaccine ligger på 94,1%.</p>
      <br />
      <p className="vac-p">Skal du vaccineres med Modernas vaccine skal du have to stik. Efter du har fået det første, skal du have næste stik efter 28 dage.</p>
      <br />
      </div>

      <div className="vacbox-two">
      <div className="h3-wrap">
        <h3 className="vac-h3">Eventuelle Bivirkninger</h3>
        </div>
        <div className="vacgrid-small">

        <div>
          <VcHeadache />
          <p>Hovedpine og træthed.</p>
          </div>
          <div>
          <VcNausea />
          <p>Kvalme og/eller opkast.</p>
          </div>
          <div>
          <VcLymphs />
          <p>Hævede lymfeknuder under den arm, hvori man er vaccineret.</p>
          </div>
          <div>
          <VcPain />
          <p>Ømhed på indstiksstedet. Smerter på injektionsstedet. Muskelsmerter og utilpashed. Ledsmerter.</p>
          </div>
          <div>
          <VcFever />
          <p>Feber og kulderystelser.</p>
          </div>
          <div>
          <VcInsomnia className="vc-opacity" />
          <p>Søvnløshed.</p>
          </div>
          <div>
          <VcRash />
          <p>Udslæt, hudkløe eller rødme ved injektionsstedet.</p>
          </div>
          <div>
          <VcFace />
          <p>Akut ansigtslammelse og hævelse i ansigtet.</p>
          <p className="red">(Sjældent)</p>
          </div>

        </div>
      </div>
      </div>
    </div>
    
      {/* Moderna column end */}

      <hr className="vac-hr" />
{/* AstraZeneca column start */}

      <h3 className="miniheader"><span className="orange">Vaxzevria</span> (tidl. AstraZeneca) Vaccinen</h3>
      <div className="az-v">
      <LogoAstra className="astraz"/>
      <div className="vaccinegrid">
      <div className="vacbox-one">
      <p className="vac-p">AstraZeneca er en af verdens største medicinalvirksomheder med hovedkvarter i Cambrige, England.</p>
      <br />
      <p className="vac-p">Den 29. januar 2021 blev AstraZenecas COVID-19 vaccine godkent som den tredje i rækken. Den er siden blevet brugt i vaccinationsprogrammet i Danmark samt i størstedelen af verden.</p><br />
      <p className="vac-p">Den estimerede effektivitet af AstraZenecas vaccine ligger på 59,5%. </p><br />
      <p className="vac-p">Bliver du vaccineret med AstraZeneca vaccinen skal du have to stik. Andet stik modtages mellem 4-12 uger efter du har modtaget det første stik.</p><br />
      <p className="vac-p">AstraZeneca vaccinen, der før hed COVID-19 Vaccine AstraZeneca, har skiftet navn til Vaxzevria.</p><br />
      <p className="vac-p orange">Brugen af denne vaccine er p.t sat i bero grundet mistanke om muligt (omend sjældne) seriøse bivirkninger.</p>
      </div>

      <div className="vacbox-two">
        <div className="h3-wrap">
        <h3 className="vac-h3">Eventuelle Bivirkninger</h3>
        </div>
        <div className="vacgrid-small">

          <div>
          <VcHeadache />
          <p>Hovedpine og træthed.</p>
          </div>
          <div>
          <VcNausea />
          <p>Kvalme og/eller opkast.</p>
          </div>
          <div>
          <VcLymphs className="vc-opacity" />
          <p>Hævede lymfeknuder under den arm, hvori man er vaccineret.</p>
          </div>
          <div>
          <VcPain />
          <p>Ømhed på indstiksstedet. Smerter på injektionsstedet. Muskelsmerter og utilpashed. Ledsmerter.</p>
          </div>
          <div>
          <VcFever />
          <p>Feber og kulderystelser.</p>
          </div>
          <div>
          <VcInsomnia className="vc-opacity" />
          <p>Søvnløshed.</p>
          </div>
          <div>
          <VcRash className="vc-opacity" />
          <p>Udslæt, hudkløe eller rødme ved injektionsstedet.</p>
          </div>
          <div>
          <VcFace className="vc-opacity" />
          <p>Akut ansigtslammelse og hævelse i ansigtet.</p>
          </div>

        </div>
      </div>
      
      {/* AstraZeneca column end */}
      </div>
    </div>

      {/* J&J column start */}
      <hr className="vac-hr" />
<h3 className="miniheader"><span className="orange">Johnson & Johnson</span> Vaccinen</h3>
      <div className="az-v">
      <LogoJJ className="astraz"/>
      <div className="vaccinegrid">
      <div className="vacbox-one">
      <p className="vac-p">Johnson & Johnson er et amerikansk multinationalt medicinalkonglomerat. De er kendtd for at producere medicin, medicinsk udstyr og husholdningsprodukter.</p>
      <br />
      <p className="vac-p">Virksomhedens hovedsæde ligger i New Brunswick i New Jersey.</p>
      <br />
      <p className="vac-p">Vaccinen fra Johnson & Johnson bliver den fjerde til at udkomme i kampen mod Coronavirus.</p>
      <br />
      <p className="vac-p">Johnson & Johnsons vaccine har en effektivitet på 67%.</p>
      <br />
      <p className="vac-p">Ved denne vaccine er det kun nødvendigt at modtage et enkelt stik.</p>
      <br />
      <p className="vac-p orange">Brugen af denne vaccine er p.t sat i bero grundet mistanke om muligt (omend sjældne) seriøse bivirkninger.</p>
      </div>

      <div className="vacbox-two">
      <div className="h3-wrap">
        <h3 className="vac-h3">Eventuelle Bivirkninger</h3>
        </div>
        <div className="vacgrid-small">

        <div>
          <VcHeadache />
          <p>Hovedpine og træthed.</p>
          </div>
          <div>
          <VcNausea />
          <p>Kvalme og/eller opkast.</p>
          </div>
          <div>
          <VcLymphs className="vc-opacity" />
          <p>Hævede lymfeknuder under den arm, hvori man er vaccineret.</p>
          </div>
          <div>
          <VcPain />
          <p>Ømhed på indstiksstedet. Smerter på injektionsstedet. Muskelsmerter og utilpashed. Ledsmerter.</p>
          </div>
          <div>
          <VcFever />
          <p>Feber og kulderystelser.</p>
          </div>
          <div>
          <VcInsomnia className="vc-opacity" />
          <p>Søvnløshed.</p>
          </div>
          <div>
          <VcRash className="vc-opacity" />
          <p>Udslæt, hudkløe eller rødme ved injektionsstedet.</p>
          </div>
          <div>
          <VcFace className="vc-opacity" />
          <p>Akut ansigtslammelse og hævelse i ansigtet.</p>
          </div>

        </div>
      </div>
      </div>
    </div>
    
      {/* J&J column end */}



    <Vaccine className="svg-img"/>
    </div>

    <div className="vac-linkbox">
    <p>Referencer: </p>
    <a className="vac-link" href="https://www.fda.gov/media/146305/download">Janssen COVID-19 Vaccine EUA Fact Sheet for Recipients and Caregivers</a><br />
    <a className="vac-link" href="https://laegemiddelstyrelsen.dk/da/nyheder/2021/johnson-johnsons-vaccine-mod-covid-19-betinget-godkendt-af-eu-kommissionen/">Johnson & Johnsons vaccine mod COVID-19 betinget godkendt af EU-Kommissionen</a><br />
    <a className="vac-link" href="https://sum.dk/nyheder/2021/marts/fjerde-vaccine-mod-covid-19-er-nu-godkendt-og-paa-vej-mod-danmark">Fjerde vaccine mod Covid-19 er nu godkendt og på vej mod Danmark</a><br />
    <a className="vac-link" href="https://www.ssi.dk/-/media/arkiv/subsites/covid19/risikovurderinger/notat---risikovurdering-for-e484k.pdf?la=da">NOTAT - Risikovurdering for E484K</a><br />
    <a className="vac-link" href="https://www.ssi.dk/aktuelt/nyhedsbreve/epi-nyt/2020/uge-51-52b---2020">SSI - Uge 51/52b - 2020</a><br />
    <a className="vac-link" href="https://www.ssi.dk/aktuelt/nyhedsbreve/epi-nyt/2021/uge-2a---2021">SSI - Uge 2a - 2021</a><br />
    <a className="vac-link" href="https://www.ssi.dk/aktuelt/nyhedsbreve/epi-nyt/2021/uge-6---2021">SSI - Uge 6 - 2021</a>
    </div>

  </div>
</div >
  );
}


export default Coronavirus;